<template>
  <div class="d-flex flex-column fill-height pb-16">
    <PageHeader>
      <div class="d-flex justify-space-between align-center">
        <h1 class="d-flex align-center text-title-1 overflow-hidden">
          <UiBtn icon class="mr-1" color="gray-60"
                 @click="isAllFiles ? $router.back() : $router.push({name: 'FilesByStyles', params: {space_id: $route.params.space_id}})">
            <IconArrowLeft width="16"/>
          </UiBtn>
          Files

          <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
          <span class="accent--text">{{ isAllFiles && !filters.project_id ? 'All Files' : 'Projects' }}</span>

          <template v-if="projectTitle">
            <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
            <v-tooltip top color="rgba(47, 49, 53, 0.7)">
              <template #activator="{attrs, on}">
                <span class="accent--text text-truncate" v-bind="attrs" v-on="on">{{ projectTitle }}</span>
              </template>
              <div class="text-captions-1">
                {{ projectTitle }}
              </div>
            </v-tooltip>
            <IconDoubleChevronRight width="15" class="mx-4 accent-70--text flex-shrink-0"/>
          </template>
          <v-tooltip v-if="styleTitle && projectTitle" top color="rgba(47, 49, 53, 0.7)">
            <template #activator="{attrs, on}">
              <span class="accent--text text-truncate" style="max-width: 200px; min-width: 100px" v-bind="attrs"
                    v-on="on">{{ styleTitle }}</span>
            </template>
            <div class="text-captions-1">
              {{ styleTitle }}
            </div>
          </v-tooltip>
          <template v-if="isAllFiles && filters.project_id && !loading">
            <IconDoubleChevronRight v-if="styleTitle" width="15" class="mx-4 accent-70--text flex-shrink-0"/>
            <span class="accent--text flex-shrink-0">All Files</span>
          </template>
        </h1>
      </div>
    </PageHeader>

    <v-container>
      <div v-if="!loading" class="d-flex align-center justify-space-between mb-9">
        <div class="d-flex">
          <UiGridViewType class="mr-6" :list="$config.project.gridView.thumbnail"
                          :active="(getGridView.filesByVersions.gridView.type === 'thumbnail' && getGridView.filesByVersions.gridView)"
                          @setGridView="setGridView({key: 'filesByVersions', value: {gridView: $event, isGrid: true}})"/>

          <UiGridViewType class="mr-6" :list="$config.project.gridView.list"
                          :active="(getGridView.filesByVersions.gridView.type === 'list' && getGridView.filesByVersions.gridView)"
                          @setGridView="setGridView({key: 'filesByVersions', value: {gridView: $event, isGrid: false}})"/>

          <UiSort
            class="mr-7"
            :list="dateOptions"
            :value1="additionalFilters.created_at"
            mandatory1
            @setSort1="(value) => setAdditionalFilters('created_at', value)"
          >
            <template #icon="{active, menu}">
              <IconCalendar width="18" :class="active || menu ? 'accent--text' : 'gray-60--text'"/>
            </template>
          </UiSort>

          <v-switch
            v-model="isAllFiles"
            flat
            dense
            hide-details
            inset
            @click="switchAllFiles"
            class="switcher my-0"
          >
            <template #label>
              <span class="text-captions-1 font-weight-semi-bold accent--text">View all files</span>
            </template>
          </v-switch>
        </div>

        <div class="d-flex align-center">
          <UiFilter
            :filterData="filterData"
            offset-y
            allow-overflow
            left
            clear-btn
            @setFilters="setFilters"
            openFilterBtnClasses="mr-4"
          />

          <UiSort
            class="mr-6"
            :list="sortOptions"
            :value1="additionalFilters.order"
            mandatory1
            @setSort1="(value) => setAdditionalFilters('order', value)"
          />
          <UiSearch style="width: 194px" @onSearch="(value) => setAdditionalFilters('search', value)"/>
        </div>
      </div>

      <div v-if="filesLoading || loading" class="d-flex justify-center pt-16">
        <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
        />
      </div>

      <template v-else-if="files.data.length && !loading && !filesLoading">
        <div v-show="getGridView.filesByVersions.isGrid" :class="['card-wr', getGridView.filesByVersions.gridView.slug]">
          <UiGridCard
            v-for="file in files.data"
            :key="file.id"
            :item="file"
            :itemStatus="file.status"
            @click="openFilePreview(file)"
          >
            <template v-slot:image>
              <div class="d-flex align-center justify-center flex-grow-1 pb-5">
                <UIImage v-if="!file" class="fill-width fill-height object-cover" :name-path="`default-img.png`"/>
                <img
                  v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(file.file.original_name))"
                  :src="getFileThumbnails(file.file.thumbnails) || file.file.url" alt="" class="fill-width fill-height object-cover">
                <UiFileIcon v-else width="50" :extension="getFileName(file.file.original_name).ext"/>
              </div>
            </template>

            <template v-slot:bottom>
              <div class="px-2">
                <UiChangeNameInput
                  v-if="file.showStyleInput"
                  v-model="file.newTitle"
                  placeholder="Enter file name"
                  :rules="'required|max:128'"
                  @onSave="updateFileName(file)"
                  @onClose="file.showStyleInput = false"
                />

                <div v-if="!file.showStyleInput" class="d-flex align-center justify-space-between">
                  <v-tooltip top color="accent">
                    <template v-slot:activator="{ on, attrs }">
                      <h6
                        v-bind="attrs" v-on="on"
                        class="d-flex align-center fill-width mb-1 text-body font-weight-semi-bold gray-100--text"
                        style="max-width: 80%"
                        @click.stop="itemDisable(file) ? file.showStyleInput = true : $event.preventDefault">
                      <span class="text-no-wrap overflow-hidden text-overflow-ellipsis">
                        {{ getFileName(file.file.original_name).name }}.
                      </span>
                        <span class="flex-shrink-0">{{ getFileName(file.file.original_name).ext }}</span>
                      </h6>
                    </template>
                    <span class="white--text font-weight-medium">{{ file.file.original_name }}</span>
                  </v-tooltip>

                  <strong class="d-flex align-center text-body font-weight-semi-bold ray-60--text">
                    V: <span class="accent-100--text">{{ file.version }}</span>
                  </strong>
                </div>

                <div class="d-flex align-end justify-space-between fill-width">
                  <div>
                    <span class="text-captions-1 gray-60--text">Updated:</span>
                    <p class="mb-0 text-captions-1 font-weight-bold gray-80--text">
                      {{ timeDistance(file.updated_at) }}</p>
                  </div>

                  <FilesVersionMenu :file="file" @deleteFile="deleteFile" @openFilePreview="openFilePreview"/>
                </div>

                <v-flex v-if="file.loading" @click.stop
                        class="absolute inset-0 d-flex justify-center align-center gray-30">
                  <v-progress-circular
                    :size="64"
                    color="accent"
                    indeterminate
                  />
                </v-flex>
              </div>
            </template>
          </UiGridCard>
        </div>

        <UiListCard
          v-show="!getGridView.filesByVersions.isGrid"
          v-for="file in files.data"
          :key="file.id"
          :item="file"
          :itemStatus="file.status"
          :selectable="false"
          :class="[`file mb-3 relative ${getGridView.filesByVersions.gridView.slug}`]"
          @click="openFilePreview(file)"
        >
          <template v-slot:image>
            <div class="d-flex align-center justify-center flex-grow-1">
              <UIImage
                v-if="!file"
                class="fill-width fill-height object-cover"
                :name-path="`default-img.png`"/>
              <img v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(file.file.original_name))" :src="getFileThumbnails(file.file.thumbnails) || file.file.url" alt="" class="fill-width fill-height object-cover">
              <UiFileIcon v-else width="30" :extension="getFileName(file.file.original_name).ext"/>
            </div>
          </template>

          <template v-slot:body>
            <div class="d-flex align-center fill-width fill-height">
              <div class="file-section px-5 mr-auto">
                <div class="d-flex align-center mb-1">
                  <UiChangeNameInput
                    v-if="file.showStyleInput"
                    v-model="file.newTitle"
                    placeholder="Enter file name"
                    :rules="'required|max:128'"
                    @onSave="updateFileName(file)"
                    @onClose="file.showStyleInput = false"
                  />

                  <v-tooltip v-else top color="accent">
                    <template v-slot:activator="{ on, attrs }">
                      <h6
                        v-bind="attrs" v-on="on"
                        class="d-flex align-center fill-width mb-1 text-body font-weight-semi-bold gray-80--text cursor-pointer"
                        style="max-width: 80%"
                        @click.stop="itemDisable(file) ? file.showStyleInput = true : $event.preventDefault">
                        <span class="text-no-wrap overflow-hidden text-overflow-ellipsis">
                          {{ getFileName(file.file.original_name).name }}.
                        </span>
                        <span class="flex-shrink-0">{{ getFileName(file.file.original_name).ext }}</span>
                      </h6>
                    </template>
                    <span class="white--text font-weight-medium">{{ file.file.original_name }}</span>
                  </v-tooltip>

                  <IconDoubleChevronRight width="10" class="mx-3 gray-30--text flex-shrink-0"/>

                  <v-tooltip top color="accent">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on"
                           class="text-captions-1 text-no-wrap overflow-hidden text-overflow-ellipsis gray-60--text">
                        V<strong class="font-weight-semi-bold gray-100--text">{{ file.version }}</strong>
                      </div>
                    </template>
                    <span class="white--text font-weight-medium">V{{ file.version }}</span>
                  </v-tooltip>
                </div>

                <div class="d-flex align-center overflow-hidden">
                  <UiProjectModuleIcon width="12" :icon="file.project_module.icon"
                                       class="mr-1 flex-shrink-0 gray-60--text"/>
                  <span class="text-captions-1 text-no-wrap text-overflow-ellipsis overflow-hidden">{{
                      file.project_module.title
                    }}</span>
                </div>
              </div>

              <div class="file-section border-left px-5">
                <p class="mb-1 text-captions-1 gray-60--text">
                  Updated: <strong class="font-weight-semi-bold">{{ timeDistance(file.updated_at) }}</strong>
                </p>
                <p class="mb-0 text-captions-1">
                  Due:
                  <strong class="font-weight-semi-bold gray-100--text">{{ dueDaysLeft(file.project_module.due_date) }}
                    {{ dueDaysLeft(file.project_module.due_date) === 1 ? 'day' : 'days' }}</strong>
                </p>
              </div>

              <div class="file-section border-left px-5 d-flex">
                <div class="ml-auto">
                  <FilesVersionMenu :file="file" @deleteFile="deleteFile" @openFilePreview="openFilePreview"/>
                </div>
              </div>
            </div>

            <v-flex v-if="file.loading" @click.stop class="absolute inset-0 d-flex justify-center align-center gray-30">
              <v-progress-circular
                :size="48"
                color="accent"
                indeterminate
              />
            </v-flex>
          </template>
        </UiListCard>
      </template>

      <v-flex v-if="!files.data.length && !loading && !filesLoading"
              class="d-flex justify-center align-center py-16 font-weight-semi-bold"
              style="font-size: 28px;"
      >
        No Files
      </v-flex>

      <PreviewModal v-if="filePreview" :file="filePreview" @onClose="filePreview = null"/>

    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {differenceInDays, format, formatDistanceToNow} from "date-fns";
import {getFileExtension, getFileName, getFileThumbnails} from "@/utils/helpers";
import PageHeader from "@/components/layout/PageHeader";
import UiBtn from "@/components/UI/UiBtn";
import UiGridViewType from "@/components/UI/UiGridViewType";
import UiSort from "@/components/UI/UiSort";
import UiFilter from "@/components/UI/UiFilter";
import UiSearch from "@/components/UI/UiSearch";
import UIImage from "@/components/UI/UIImage";
import UiChangeNameInput from "@/components/UI/UiChangeNameInput";
import UiGridCard from "@/components/UI/UiGridCard";
import UiListCard from "@/components/UI/UiListCard";
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon";
import FilesVersionMenu from "@/views/files/by-versions/FilesVersionMenu";
import PreviewModal from "@/views/files/by-versions/PreviewModal";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: 'FilesByVersions',
  components: {
    UiFileIcon,
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
    IconCalendar: () => import('@/components/icons/IconCalendar'),

    PageHeader,
    UiBtn,
    UiGridViewType,
    UiSort,
    UiFilter,
    UiSearch,
    UIImage,
    UiChangeNameInput,
    UiGridCard,
    UiListCard,
    UiProjectModuleIcon,
    FilesVersionMenu,
    PreviewModal,
  },
  data() {
    return {
      loading: true,
      filesLoading: false,
      isAllFiles: this.$route.name === 'FilesAll',
      filters: {
        project_id: null,
        project_tag_id: null,
        module: null,
        statuses: [],
        file_type: []
      },
      additionalFilters: {
        search: '',
        created_at: null,
        order: null,
      },
      sortOptions: [
        {name: 'Date Ascending', slug: 'asc_date'},
        {name: 'Date Descending', slug: 'desc_date'},
        {name: 'A-Z', slug: 'asc_text'},
        {name: 'Z-A', slug: 'desc_text'}
      ],
      fileTypes: [
        {id: 'JPG', title: 'JPG'},
        {id: 'JPEG', title: 'JPEG'},
        {id: 'PNG', title: 'PNG'},
        {id: 'GIF', title: 'GIF'},
        {id: 'PDF', title: 'PDF'},
        {id: 'XLSX', title: 'XLSX'},
        {id: 'XLS', title: 'XLS'},
        {id: '3DM', title: '3DM'},
        {id: 'STL', title: 'STL'},
        {id: 'OBJ', title: 'OBJ'},
        {id: 'JCD', title: 'JCD'},
        {id: 'OTHER', title: 'OTHER'}
      ],
      dateOptions: [
        {name: 'All', slug: 'all'},
        {name: 'Last 7 days', slug: '7'},
        {name: 'Last 30 days', slug: '30'},
        {name: 'Last 3 Months', slug: '90'},
      ],
      filterData: [],
      project: null,
      style: null,
      files: {
        data: [],
        nextLink: null
      },
      designModules: [],
      filePreview: null,
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getProjectPermissionData',
      'getActiveSpace',
      'getGridView'
    ]),
    getActiveDesignModule() {
      return this.designModules.find(module => module.id === this.filters.module);
    },

    currentProjectInFilter() {
      const projects = this.filterData.find(filter => filter.type === 'project_id')?.list || []

      return projects.find(project => project.id === +this.$route.query?.project_id)
    },
    projectTitle() {
      return this.project?.title || this.currentProjectInFilter?.title
    },
    styleTitle() {
      if (this.isAllFiles && this.$route.query?.project_style_id) {
        return this.files.data[0]?.style.title
      }
      return this.style?.title
    }
  },
  async created() {
    if (!this.isAllFiles) {
      const projectRes = await this.$api.project.get(this.$route.params.project_id);
      this.project = projectRes.data;

      this.designModules = this.project.modules.filter(module => module.type === this.$config.project.moduleTypes.design);
    }



    const {created_at, project_id, project_tag_id, module, statuses, file_type, search, order} = this.$route.query;
    const filters = {
      project_id: project_id ? +project_id : null,
      project_tag_id: project_tag_id ? +project_tag_id : null,
      statuses: !statuses ? [] : statuses.split(',').map(item => +item),
      file_type: !file_type ? [] : file_type.split(','),
    };

    if (this.$route.params.space_id !== 'default') {
      filters.space_id = this.$route.params.space_id
      await this.$store.dispatch('setActiveSpace', this.$route.params.space_id)
    } else {
      filters.space_id = null
    }

    if (this.isAllFiles) {
      filters.module = module ? +module : null;
    } else {
      filters.module = !module ? this.designModules[0].id : +module;
    }

    this.filters = filters;
    this.additionalFilters = {
      search,
      created_at: this.dateOptions.find(option => option.slug === created_at) || this.dateOptions[0],
      order: this.sortOptions.find(option => option.slug === order) || this.sortOptions[0]
    }



    const [files, style, extensions] = await Promise.all([this.loadFiles(), this.loadStyle(), this.$api.filesystem.getFilesExtensions({space: this.getActiveSpace?.id? [this.getActiveSpace?.id] : []})])
    this.style = style.data;

    this.fileTypes = extensions.data.map((ext) => {
       return  {id: ext, title: ext}
    })
    this.initFiltersData();

    await this.$router.$updateQueryParams({
      ...filters,
      search: this.additionalFilters.search,
      created_at: this.additionalFilters.created_at.slug,
      order: this.additionalFilters.order.slug,
    });
    this.loading = false;
  },
  methods: {
    ...mapActions([
      'setGridView'
    ]),
    getFileThumbnails,
    getFileExtension,
    getFileName,
    loadStyle() {
      if (this.isAllFiles) return Promise.resolve({data: null});
      return this.$api.projectModuleDesign.filesList(this.$route.params.project_id, this.filters.module, this.$route.params.style_id);
    },
    loadFiles(url = `/files`) {
      this.filesLoading = true;

      const params = {
        ...this.filters,
        project_id: this.isAllFiles ? this.filters.project_id : this.project.id,
        project_module: this.filters.module ? [this.filters.module] : null,
        search: this.additionalFilters.search,
        created_at: this.additionalFilters.created_at.slug,
        order: this.additionalFilters.order.slug,
        space_id: this.getActiveSpace?.id || null
      };

      if (!this.isAllFiles) {
        params.project_style_id = this.$route.params.style_id;
      }

      if (this.isAllFiles && this.$route.query?.project_style_id) {
        params.project_style_id = this.$route.query.project_style_id;
      }

      return this.$api.filesystem.getFilesList(url, params)
        .then(res => {
          this.files = {
            data: [...this.files.data, ...res.data].map(file => {
              return {
                ...file,
                newTitle: file.file.original_name.split('.')[0],
                showStyleInput: false,
                loading: false
              }
            }),
            nextLink: res.links.next
          };
        }).finally(() => {
          this.filesLoading = false;
        })
    },
    deleteFile(file_id) {
      this.files.data = this.files.data.filter(file => file.id !== file_id);
    },
    itemDisable(file) {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      return (file.created_by === this.getAuthId ||
        this.getProjectPermissionData(file.project.id).roles.some(role => role === projectOwner || role === projectManager)
      );
    },
    timeDistance(date) {
      const now = new Date();
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), 'MM-dd-yyyy');
      } else {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace('about ', '');
      }
    },
    dueDaysLeft(date) {
      const now = new Date();
      const dueData = new Date(date);
      return differenceInDays(dueData, now);
    },
    async updateFileName(file) {
      file.loading = true;

      try {
        const res = await this.$api.filesystem.updateFile(file.file.id, {
          original_name: `${file.newTitle}.${file.file.original_name.split('.')[1]}`
        });
        file.file.original_name = res.data.original_name;
      } catch (error) {
        console.error(error);
      } finally {
        file.loading = false;
        file.showStyleInput = false;
      }
    },
    async initFiltersData() {
      let designModules = [];
      let projectsList = [];
      const existingFilters = this.filters;
      const statuses = Object.keys(this.$config.project.statusLabels).reduce((acc, status) => {
        if (+status !== this.$config.project.status.draft) {
          acc.push({id: +status, title: this.$config.project.statusLabels[status]});
        }
        return acc;
      }, []);

      if (this.isAllFiles) {
        const [projects, tags, modules] = await Promise.all([
          this.$api.project.list({counter: -1, space_id: this.getActiveSpace?.id || null }),
          this.$api.tag.list(),
          this.$api.module.list(),
        ]);

        projectsList = projects.data.filter(project => project.total_files);
        designModules = modules.filter(module => module.type === this.$config.project.moduleTypes.design);

        this.filterData.push({
          type: 'project_tag_id',
          title: 'Tags',
          list: tags,
          activeFilters: existingFilters['project_tag_id'] || []
        });
      }

      this.filterData.push(
        {
          type: 'statuses',
          title: 'Status',
          list: statuses,
          status_indicator: true,
          isMultiple: true,
          activeFilters: existingFilters['statuses'] || []
        },
        {
          type: 'module',
          title: 'Design Module',
          list: this.isAllFiles ? designModules : this.designModules,
          activeFilters: existingFilters['module'] || []
        },
        {
          type: 'file_type',
          title: 'File Type',
          list: this.fileTypes,
          isMultiple: true,
          activeFilters: existingFilters['file_type'] || []
        }
      );

      if (this.isAllFiles) {
        this.filterData.push({
          type: 'project_id',
          title: 'Projects',
          list: projectsList,
          activeFilters: existingFilters['project_id'] || []
        });
      }
    },
    setFilters({data: newVal}) {
      this.files = {
        data: [],
        nextLink: null
      };

      this.filterData = newVal;
      this.filterData.forEach(el => {
        if (Array.isArray(el.activeFilters)) {
          this.filters[el.type] = el.activeFilters.map(i => i.toString());
        } else {
          if (el.activeFilters) {
            this.filters[el.type] = el.activeFilters;
          } else {
            this.filters[el.type] = Array.isArray(el.activeFilters) ? [] : null;
          }
        }
      });

      const projects = newVal.find(filter => filter.type === 'project_id')?.list || []

      const project= projects.find(project => project.id === +this.filters?.project_id)

      const styleId = project?.styles?.find?.(style => style.id === +this.$route.query.project_style_id)?.id || null

      this.$router.$updateQueryParams({project_style_id: styleId, ...this.filters});
      this.loadFiles();
    },
    setAdditionalFilters(key, value) {
      this.files = {
        data: [],
        nextLink: null
      };

      this.additionalFilters[key] = value;

      this.$router.$updateQueryParams({[key]: typeof value === 'string' ? value : value.slug});
      this.loadFiles();
    },
    openFilePreview(file) {
      this.filePreview = file;
    },
    switchAllFiles() {
      if (this.$route.name === 'FilesAll') {
        this.$router.push(sessionStorage.getItem('prevUrl'));
      } else {
        this.$router.push({
          name: 'FilesAll',
          params: { space_id: this.$route.params.space_id},
          query: {project_id: this.$route.params.project_id, project_style_id: this.$route.params.style_id}
        });
      }
    }
  },
}
</script>

<style scoped lang="scss">
.switcher {
  ::v-deep .v-input--switch__thumb {
    top: calc(50% - 5px);
    width: 10px;
    height: 10px;
  }

  &.v-input--is-dirty ::v-deep .v-input--switch__thumb {
    left: -10px;
    background-color: var(--v-gray-0-base) !important;
  }

  &.v-input--switch--inset ::v-deep .v-input--switch__track {
    top: calc(50% - 7px);
    left: -2px;
    height: 14px;
    width: 24px;
    background-color: var(--v-gray-60-base);
  }

  &.v-input--is-dirty.v-input--switch--inset ::v-deep .v-input--switch__track {
    opacity: 1;
    background-color: var(--v-accent-base);
  }

  ::v-deep .v-input--selection-controls__ripple {
    top: calc(50% - 21px);
    left: -11px;

  }

  &.v-input--is-dirty ::v-deep .v-input--selection-controls__ripple {
    left: -31px !important;
  }

  ::v-deep .v-input--selection-controls__input {
    width: 24px;
  }
}

.card-wr {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  &.large {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &.medium {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.small {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .card {
    border-color: var(--v-gray-30-base);

    &:hover {
      border-color: var(--v-gray-60-base);
      background-color: var(--v-gray-10-base);
    }
  }
}

::v-deep .card__image {
  background: var(--v-gray-30-base);
}

.file {
  border-color: var(--v-gray-30-base);

  .file-section {
    width: 30%;

    &:nth-child(1) {
      width: 40%;
    }
  }

  .border-left::before {
    content: '';
    position: absolute;
    top: 18px;
    bottom: 18px;
    margin-left: -20px;
    border-left: 1px solid var(--v-gray-20-base);
  }
}

</style>
